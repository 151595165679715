<template>
  <div
   style="width: 80%;margin-left: 10%;padding:right:40px;"
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
  >
    <div>
      <div class="new-item-head">
        TECH DOC. EDITOR Library Preview
        <span
          style="position: absolute; left: 10px; cursor: pointer"
          @click="back"
        >
          <i class="el-icon-arrow-left"></i>
        </span>
        <span
        v-if="!hideHead"
          style="
            position: absolute;
            right: 10px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
          "
        >
          <el-dropdown placement>
            <div>
              <i class="el-icon-user" style="margin-right: 10px"></i>
              {{ username }}
              <i class="el-icon-arrow-down"></i>
              <el-dropdown-menu class="user-dropdown" slot="dropdown">
                <el-dropdown-item @click.native="logout"
                  >Logout</el-dropdown-item
                >
              </el-dropdown-menu>
            </div>
          </el-dropdown>
        </span>
      </div>
      <div style="margin-bottom:100px;background:#ffffff;padding:20px 0;width:100%;">
        <div id="pdfDom">
          <div id="printSpecDiv" v-show="printType =='spec'">
              <template v-if="params.productType == 'CSFMU-S' || params.productType == 'CSFMU-B' || params.productType == 'SDNP'">
                  <CSFMU v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></CSFMU>
                  <CSFMU v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></CSFMU>
              </template>
              <template v-if="params.productType == 'F&V'">
                  <FANDV v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></FANDV>
                  <FANDV v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></FANDV>
              </template>
              <template v-if="params.productType == 'FMU-D'">
                  <FMUD v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></FMUD>
                  <FMUD v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></FMUD>
              </template>
              <template v-if="params.productType == 'HEXT'">
                  <HEXT v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></HEXT>
                  <HEXT v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></HEXT>
              </template>
              <template v-if="params.productType == 'PCG'">
                  <PCG v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></PCG>
                  <PCG v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></PCG>
              </template>
              <template v-if="params.productType == 'REXT'">
                  <REXT v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></REXT>
                  <REXT v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></REXT>
              </template>
              <template v-if="params.productType == 'OEXT'">
                  <OEXT v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></OEXT>
                  <OEXT v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></OEXT>
              </template>
              <template v-if=" params.productType == 'PCG-CSFMU-S' || params.productType == 'PCG-CSFMU-B'">
                  <PCGCSFM v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></PCGCSFM>
                  <PCGCSFM v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></PCGCSFM>
              </template>
              <template v-if="params.productType == 'SHXTO'">
                  <SHXTO v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></SHXTO>
                  <SHXTO v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></SHXTO>
              </template>
              <template v-if="params.productType == 'EHXTO'">
                  <EHXTO v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></EHXTO>
                  <EHXTO v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></EHXTO>
              </template>
              <template v-if="params.productType == 'Liquid EXT-S' || params.productType == 'Liquid EXT-B' || params.productType == 'SDNP-L'">
                  <LiquidEXT v-if="!printShow" :printModel="this.$data" style="padding: 0 40px 0 40px"></LiquidEXT>
                  <LiquidEXT v-if="printShow" :printModel="this.$data" style="height-max: 1000px;width: 100vw;padding: 0 40px 0 40px"></LiquidEXT>
              </template>
          </div>
          <div id="printSdsDiv" v-show="printType =='sds'">
              <SDSPowder style="padding: 0 40px 0 40px" :printModel="this.$data" v-if="!printShow && formModel.sdsType == 'Powder'"></SDSPowder>
              <SDSPowder :printModel="this.$data" style="width: 100vw" v-if="printShow && formModel.sdsType == 'Powder'"></SDSPowder>
              <SDSOil style="padding: 0 40px 0 40px" :printModel="this.$data" v-if="!printShow && formModel.sdsType == 'Oil'"></SDSOil>
              <SDSOil :printModel="this.$data" style="width: 100vw" v-if="printShow && formModel.sdsType == 'Oil'"></SDSOil>
              <SDSLiquid style="padding: 0 40px 0 40px" :printModel="this.$data" v-if="!printShow && formModel.sdsType == 'Liquid'"></SDSLiquid>
              <SDSLiquid :printModel="this.$data" style="width: 100vw" v-if="printShow && formModel.sdsType == 'Liquid'"></SDSLiquid>
          </div>
          <div id="printCompDiv" v-show="printType =='composition'">
              <CompositionBreakdown :printModel="this.$data" style="height-max: 1000px"></CompositionBreakdown>
          </div>
          <div id="printCoaDiv" v-show="printType =='coa'">
            <CoaLiquid v-if="!printShow && form.productType=='Liquid'" :printModel="this.$data" style="padding: 0 40px 0 40px"></CoaLiquid>
            <CoaLiquid v-if="printShow && form.productType=='Liquid'" :printModel="this.$data" style="height-max: 1000px; width: 100vw"></CoaLiquid>
            <COAOilEhxto v-if="!printShow && form.productType=='EHXTO'" :printModel="this.$data" style="padding: 0 40px 0 40px"></COAOilEhxto>
            <COAOilEhxto v-if="printShow && form.productType=='EHXTO'" :printModel="this.$data" style="height-max: 1000px; width: 100vw"></COAOilEhxto>
            <COAOilShxto v-if="!printShow && form.productType=='SHXTO'" :printModel="this.$data" style="padding: 0 40px 0 40px"></COAOilShxto>
            <COAOilShxto v-if="printShow && form.productType=='SHXTO'" :printModel="this.$data" style="height-max: 1000px; width: 100vw"></COAOilShxto>
            <COAPowder v-if="!printShow && form.productType=='Powder'" :printModel="this.$data" style="padding: 0 40px 0 40px"></COAPowder>
            <COAPowder v-if="printShow && form.productType=='Powder'" :printModel="this.$data" style="height-max: 1000px; width: 100vw"></COAPowder>
          </div>
        </div>
        <div style="margin-top:10px;">
            <el-button v-if="printType =='spec'" type="primary" icon="el-icon-printer" @click="printSpecPage">Print</el-button>
            <el-button v-if="printType =='sds'" type="primary" icon="el-icon-printer" @click="printSdsPage">Print</el-button>
            <el-button v-if="printType =='composition'" type="primary" icon="el-icon-printer" @click="printCompPage">Print</el-button>
            <el-button v-if="printType =='coa'" type="primary" icon="el-icon-printer" @click="printCoaPage">Print</el-button>
            <a style="color: red;margin-left:10px;" v-show="pdfUrl.url" :href="pdfUrl.url" target="_blank">PDF查看</a>
            <!-- <el-button style="margin-left:10px;" type="primary" @click="onClickDownLoad">上传PDF文件</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from 'print-js'
import CSFMU from "../PrintProductTypeModule/CSFMU.vue";
import EHXTO from "../PrintProductTypeModule/EHXTO.vue";
import FANDV from "../PrintProductTypeModule/FANDV.vue";
import FMUD from "../PrintProductTypeModule/FMU-D.vue";
import HEXT from "../PrintProductTypeModule/HEXT.vue";
import LiquidEXT from "../PrintProductTypeModule/LiquidEXT.vue";
import OEXT from "../PrintProductTypeModule/OEXT.vue";
import PCGCSFM from "../PrintProductTypeModule/PCG-CSFM.vue";
import PCG from "../PrintProductTypeModule/PCG.vue";
import REXT from "../PrintProductTypeModule/REXT.vue";
import SHXTO from "../PrintProductTypeModule/SHXTO.vue";
import SDSPowder from "../PrintProductTypeModule/SDS-Powder.vue";
import SDSOil from "../PrintProductTypeModule/SDS-Oil";
import SDSLiquid from "../PrintProductTypeModule/SDS-Liquid";
import CompositionBreakdown from "../PrintProductTypeModule/CompositionBreakdown.vue";
import COAPowder from "../PrintProductTypeModule/COA-Powder";
import CoaLiquid from "../PrintProductTypeModule/COA-L";
import COAOilEhxto from "../PrintProductTypeModule/COA-OilEhxto";
import COAOilShxto from "../PrintProductTypeModule/COA-OilShxto";
import html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default {
  name: "new-item",
  mixins: [base],
  components: {
    CSFMU,
    EHXTO,
    FANDV,
    FMUD,
    HEXT,
    LiquidEXT,
    OEXT,
    PCGCSFM,
    PCG,
    REXT,
    SHXTO,
    SDSPowder, SDSOil, SDSLiquid,
    CompositionBreakdown,
    COAPowder,CoaLiquid,COAOilEhxto,COAOilShxto
  },
  data() {
    return {
      printType:"",//spec,sds,composition,tps
      printShow: false,
      loading: false,
      libraryId:0,
      commonNameArr: [],
      preservativeArr: [],
      majorConstituentsInNativeHerbsArr: [],
      processingArr: [],
      ingredientsArr: [],
      inclNameArr: [],
      botanicalNameArr: [],
      commonOtherNameArr: [],
      partUsedArr: [],
      percentageArr: [],
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason:"",
          approvedBy: "",
          isComplete:false,
        },
      ],
      params: {
        revNumber: 0,
        revDate: [],
        documentNotes: "",
        noteBy: "",
        storage: "",
        percentage: "",
        liquidExtCustomerKey1: "",
        liquidExtCustomerValue1: "",
        liquidExtCustomerKey2: "",
        liquidExtCustomerValue2: "",
        liquidExtCustomerKey3: "",
        liquidExtCustomerValue3: "",
        liquidExtCustomerKey4: "",
        liquidExtCustomerValue4: "",
        liquidExtCustomerKey5: "",
        liquidExtCustomerValue5: "",

        liquidExtCustomerValue3TM: "",
        liquidExtCustomerValue4TM: "",
        liquidExtCustomerValue5TM: "",
        ehxtoIcip: "",
        ehxtoHomogeneity: "",
        ehxtoExtractionProcess:
          "Controlled-temperature water extraction process, spray dried",
        ehxtoSolventExtraction: "",
        ehxtoCarrier: "",
        ehxtoPesticides: "",
        ehxtoCertification: "",
        ehxtoCustomerKey1: "",
        ehxtoCustomerValue1: "",
        ehxtoTestLimitCustomerKey1: "",
        ehxtoTestLimitCustomerValue1: "",
        ehxtoTestLimitCustomerKey2: "",
        ehxtoTestLimitCustomerValue2: "",
        ehxtoTestLimitCustomerKey3: "",
        ehxtoTestLimitCustomerValue3: "",
        ehxtoTestLimitCustomerKey4: "",
        ehxtoTestLimitCustomerValue4: "",
        ehxtoTestMethodCustomerKey1: "",
        ehxtoTestMethodCustomerValue1: "",
        ehxtoTestMethodCustomerKey2: "",
        ehxtoTestMethodCustomerValue2: "",
        ehxtoTestMethodCustomerKey3: "",
        ehxtoTestMethodCustomerValue3: "",
        ehxtoTestMethodCustomerKey4: "",
        ehxtoTestMethodCustomerValue4: "",
        productSubType: "",
        // 新增字段
        otherNotesRevision: "",

        microbiologicalCustomerKey1: "",
        microbiologicalCustomerValue1: "",
        microbiologicalTestMethodCustomerKey1: "",
        microbiologicalTestMethodCustomerValue1: "",

        specificationCustomerKey1: "",
        specificationCustomerValue1: "",
        testMethodCustomerKey1: "",
        testMethodCustomerValue1: "",

        specificationCustomerKey2: "",
        specificationCustomerValue2: "",
        testMethodCustomerKey2: "",
        testMethodCustomerValue2: "",

        specificationCustomerKey3: "",
        specificationCustomerValue3: "",
        testMethodCustomerKey3: "",
        testMethodCustomerValue3: "",

        specificationCustomerKey4: "",
        specificationCustomerValue4: "",
        testMethodCustomerKey4: "",
        testMethodCustomerValue4: "",

        specificationCustomerKey5: "",
        specificationCustomerValue5: "",
        testMethodCustomerKey5: "",
        testMethodCustomerValue5: "",

        generalSpecificationKey1: "",
        generalSpecificationValue1: "",
        generalSpecificationKey2: "",
        generalSpecificationValue2: "",
        generalSpecificationKey3: "",
        generalSpecificationValue3: "",
        productId: "",
        processingAid: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
        customKey3: "",
        customValue3: "",
        processing: "",
        countryOfOrigin: "",
        color: "Compares to standard",
        odor: "",
        taste: "",
        testLimitMoisture: "",
        testMethodMoisture: "",
        testLimitSpecificaGravity: "",
        testMethodSpecificaGravity: "",
        testLimitRefractiveIndex: "",
        testMethodRefractiveIndex: "",
        testLimitOpticalRotation: "",
        testMethodOpticalRotation: "",
        testLimitArsenic: "",
        testMethodArsenic: "",
        testLimitCadmium: "",
        testMethodCadmium: "",
        testLimitLead: "",
        testMethodLead: "",
        testLimitMercury: "",
        testMethodMercury: "",
        testLimitAcidValue: "",
        testMethodAcidValue: "",
        testLimitPeroxideValue: "",
        testMethodPeroxideValue: "",
        testLimitSaponificationValue: "",
        testMethodSaponificationValue: "",
        testLimitUnsaponifiableMaster: "",
        testMethodUnsaponifiableMaster: "",
        testLimitMoistureVolatiles: "",
        testMethodMoistureVolatiles: "",
        testLimitPesticides: "",
        testMethodPesticides: "",
        testLimitThcResidue: "",
        testMethodThcResidue: "",

        fattryLimitCaprylic: "",
        fattryMethodCaprylic: "",
        fattryLimitCapric: "",
        fattryMethodCapric: "",

        fattryLimitLauric: "",
        fattryMethodLauric: "",
        fattryLimitMyristic: "",
        fattryMethodMyristic: "",
        fattryLimitMyristoleic: "",
        fattryMethodMyristoleic: "",
        fattryLimitArachidic: "",
        fattryMethodArachidic: "",
        fattryLimitArachidonic: "",
        fattryMethodArachidonic: "",
        fattryLimitEicosapentaenoic: "",
        fattryMethodEicosapentaenoic: "",
        fattryLimitDocosahexaenoic: "",
        fattryMethodDocosahexaenoic: "",

        fattryLimitLinoleicacid: "",
        fattryMethodLinoleicacid: "",
        fattryLimitPunicicacid: "",
        fattryMethodPunicicacid: "",

        fattryLimitPalmitica: "",
        fattryMethodPalmitica: "",
        fattryLimitPalmitoleic: "",
        fattryMethodPalmitoleic: "",
        fattryLimitStearic: "",
        fattryMethodStearic: "",
        fattryLimitOleicVaccenic: "",
        fattryMethodOleicVaccenic: "",
        fattryLimitLinoleic: "",
        fattryMethodLinoleic: "",
        fattryLimitALinolenic: "",
        fattryMethodALinolenic: "",
        testLimitCustomKey1: "",
        testLimitCustomKey1Value: "",
        testMethodCustomKey1Value: "",
        testLimitCustomKey2: "",
        testLimitCustomKey2Value: "",
        testMethodCustomKey2Value: "",
        fattryLimitKey1: "",
        fattryLimitKey1Value: "",
        fattryMethodKey1Value: "",
        fattryLimitKey2: "",
        fattryLimitKey2Value: "",
        fattryMethodKey2Value: "",
        fattryLimitKey3: "",
        fattryLimitKey3Value: "",
        fattryMethodKey3Value: "",
        fattryLimitKey4: "",
        fattryLimitKey4Value: "",
        fattryMethodKey4Value: "",
        fattryLimitKey5: "",
        fattryLimitKey5Value: "",
        fattryMethodKey5Value: "",
        fattryLimitC8: "",
        fattryMethodC8: "",
        fattryLimitC10: "",
        fattryMethodC10: "",
        fatterLimitOthers: "",
        fatterMethodOthers: "",
        packageInfo: "",
        productDefinition: "",
        carrierSolvent: "",
        preservative: "",
        botanicalExtractionProcess: "",
        botanicalSolventForExtraction: "",
        analysisRangeBioactivesLevel: "",
        analysisTestBioactivesLevel: "",
        analysisRangePh: "",
        analysisRangeHeavyMetal: "Conforms to USP <231>",
        analysisTestPh: "",
        analysisRangePesticide: "Conforms to USP<565>",
        analysisTestPesticide: "",
        analysisRangeTotalPlateCount: "100",
        analysisTestTotalPlateCount: "",
        analysisRangeYeastMold: "100",
        analysisMethodPh: "",
        analysisMethodHeavyMetal: "",
        analysisMethodPesticide: "",
        analysisMethodTotalPlateCount: "100",
        analysisMethodYeastMold: "100",
        majorConstituentsInNativeHerbs: "",
        testLimitHeavyMetals: "10",

        analysisArsenic: "1",
        analysisCadmium: "1",
        analysisLead: "1",
        analysisMercury: "1",

        analysisMethodArsenic: "USP<232>",
        analysisMethodCadmium: "USP<232>",
        analysisMethodLead: "USP<232>",
        analysisMethodMercury: "USP<232>",

        productName: "",
        itemCode: "",
        productDescription: "",
        generalSpecificatio: "",
        createTime: "",
        productType: "",
        ingredients: "",
        productGrade: "",
        inciName: "",
        botanicalName: "",
        commonOtherName: "",
        partUsed: "",
        extractionRatio: "",
        importantConstitueInPlant: "",
        appearance: "",
        solubility: "Water soluble/dispersible",
        homogeneity: "",
        extractionProcess: "",
        solventForExtracti: "Water",
        carrier: "",
        pesticides: "",
        certification: "Kosher certified, HALAL certified",
        specificationStandard1: "",
        specificationStandard2: "",
        specificationStandard3: "",
        specificationMeshSize: "",
        specificationLossonDrying: "6",
        specificationBulkDensity: "0.25-0.75",
        specificationArsenic: "",
        specificationCadmium: "",
        specificationLead: "",
        specificationMercury: "",
        specificationHptlcIdentification: "",
        specificationTotalHeavyMetals: "10",
        specificationColor: "Compares to standard",
        specificationOrdor: "Compares to standard",
        specificationTaste: "Compares to standard",
        testMethodStandard1: "DracoUV-",
        testMethodStandard2: "DracoHPLC-",
        testMethodStandard3: "",
        testMethodMeshSize: "",
        testMethodLossOnDrying: "",
        testMethodBulkDensity: "",
        testMethodHptlcIdentification: "",
        testMethodHeavyMetals: "10",
        testMethodColor: "",
        testMethodOrdor: "Organoleptic",
        testMethodTaste: "Organoleptic",
        testMethodTotalColiforms: "FDA-BAM",
        specificationTotalPlateCount: "",
        specificationYeastAndMold: "100",
        specificationEcoli: "",
        specificationSalmonella: "",
        specificationStaphAureus: "",
        specificationTotalColiforms: "",
        testMethodTotalPlateCount: "FDA-BAM",
        testMethodYeastAndMold: "FDA-BAM",
        testMethodEcoli: "FDA-BAM",
        testMethodSalmonella: "FDA-BAM",
        testMethodTaphAureus: "FDA-BAM",
        shelflife: "",
        shelflifeNumber: "",
        stotage: "",
        fattyAcidComposition: "",
        shTest: "",
        confidential: false,
        physicalProperties: "",
        genSpecCustomField: "",
        analysisCustomField: "",
        micTestsCustomField: "",

        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
        tableInputCustomField: "",
        customFormula: false,
        customer: ""
      },
      form:{},
      formModel:{},
      fattyAcidCompositionArr: [],
      shTestArr: [],
      shTest: {
        testLimitAcidValue: "",
        testLimitPeroxideValue: "",
        testLimitSaponificationValue: "",
        testLimitUnsaponifiableMaster: "",
        testLimitMoistureVolatiles: "",
        testLimitHeavyMetals: "",
        testLimitLead: "",
        testLimitArsenic: "",
        testLimitCadmium: "",
        testLimitMercury: "",
        testLimitPesticides: "USP <561>",
        testMethodAcidValue: "AOCS Cd 3d-63",
        testMethodPeroxideValue: "AOCS Cd 8b-90",
        testMethodSaponificationValue: "AOCS Cd 3-25",
        testMethodUnsaponifiableMaster: "AOCS Ca 6a-40",
        testMethodMoistureVolatiles: "AOCS Ca 2d-25",
        testMethodLead: "AOCS Ca 18c-91",
        testMethodArsenic: "AOAC 986.15",
        testMethodCadmium: "AOAC 986.15",
        testMethodMercury: "AOAC 971.21",
        testMethodPesticides: "USP <561>",
        testCustomFieldAttr: []
      },
      fattyAcidComposition: {
        fattryLimitPalmitica: "", //28.0-35.0
        fattryLimitPalmitoleic: "", //25.0-55.0
        fattryLimitStearic: "", //0-3.0
        fattryLimitOleicVaccenic: "", //21.0-32.0
        fattryLimitLinoleic: "", //3.0-12.0
        fattryLimitALinolenic: "", //0-4.0
        fatterLimitOthers: "", //0-3.0
        fattryMethodPalmitica: "AOCS Ce 1e-91",
        fattryMethodPalmitoleic: "AOCS Ce 1e-91",
        fattryMethodStearic: "AOCS Ce 1e-91",
        fattryMethodOleicVaccenic: "AOCS Ce 1e-91",
        fattryMethodLinoleic: "AOCS Ce 1e-91",
        fattryMethodALinolenic: "AOCS Ce 1e-91",
        fatterMethodOthers: "AOCS Ce 1e-91",
        fattryLimitCaprylic: "",
        fattryMethodCaprylic: "AOCS Ce 1e-91",
        fattryLimitCapric: "",
        fattryMethodCapric: "AOCS Ce 1e-91",
        fattryLimitLinoleicacid: "",
        fattryMethodLinoleicacid: "AOCS Ce 1e-91",
        fattryLimitPunicicacid: "",
        fattryMethodPunicicacid: "AOCS Ce 1e-91",

        fattryLimitLauric: "",
        fattryMethodLauric: "AOCS Ce 1e-91",
        fattryLimitMyristic: "",
        fattryMethodMyristic: "AOCS Ce 1e-91",
        fattryLimitMyristoleic: "",
        fattryMethodMyristoleic: "AOCS Ce 1e-91",
        fattryLimitArachidic: "",
        fattryMethodArachidic: "AOCS Ce 1e-91",
        fattryLimitArachidonic: "",
        fattryMethodArachidonic: "AOCS Ce 1e-91",
        fattryLimitEicosapentaenoic: "",
        fattryMethodEicosapentaenoic: "AOCS Ce 1e-91",
        fattryLimitDocosahexaenoic: "",
        fattryMethodDocosahexaenoic: "AOCS Ce 1e-91",
        fattyCustomFieldAttr: []
      },
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      micTestsCustomFieldAttr: [],
      tableInputCustomFieldAttr: [],
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        productDescription: "",
        generalSpecificatio: "",
        productType: "",
        productSubType: "",
        ingredients: "",
        inciName: "",
        botanicalName: "",
        commonName: "",
        commonOtherName: "",
        partUsed: "",
        appearance: "",
        verified: "",
        verifiedName: "",
        verifiedTime: "",
        createTime: "",
        updateTime: "",
        createName: "",
        editName: "",
        jsonValue: ""
      },
      CompCustomRowArr1:[],
      CompCustomRowArr2:[],
      CompCustomRowArr3:[],
      fileModel: {
        SupplierSpecFileList: [],
        SupplierCoaFileList: [],
        SupplierSdsFileList: [],
        OthersAttachmentsFileList: [],
        CustomRowFileList1: [],
        CustomRowFileList2: [],
        CustomRowFileList3: [],
      },
      source:'',
      hideHead:false,
      pdfUrl:{},
      pdfName:"",
      uploadPdf:0,//1生成PDF并上传
      baseInfoCustomFieldList:[],
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    }
  },
  methods: {
    back() {
      this.$router.push({ path: "/file" + (this.hideHead ? '?source=' + this.source : '') });
    },
    onClickDownLoad() {
      this.getPdf()
    },
    getPdf() {
      // this.loading = true
      html2Canvas(document.querySelector('#pdfDom'), {
        allowTaint: false,
        taintTest: false,
        logging: false,
        useCORS: true,
        dpi: window.devicePixelRatio*4, //将分辨率提高到特定的DPI 提高四倍
        scale:4 //按比例增加分辨率
      }).then(canvas=>{
        var pdf = new jsPDF('p', 'mm', 'a4');    //A4纸，纵向
        var ctx = canvas.getContext('2d'),
            a4w = 190, a4h = 277,    //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
            imgHeight = Math.floor(a4h * canvas.width / a4w),    //按A4显示比例换算一页图像的像素高度
            renderedHeight = 0;
        while(renderedHeight < canvas.height) {
          var page = document.createElement("canvas");
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight);//可能内容不足一页

          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
          pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width));    //添加图像到页面，保留10mm边距
          
          renderedHeight += imgHeight;
          if(renderedHeight < canvas.height) {
              pdf.addPage();//如果后面还有内容，添加一个空页
          }
          // delete page;
        }
        console.log('pdf.output("blob")')
        let blob = pdf.output("blob")
        let file = new window.File([blob], this.pdfName)
        console.log(file)
        console.log(this.pdfName)
        console.log("======uploadFile=====");
        console.log(file);
        var filelist = new FormData();
        filelist.append("file", file);
        filelist.append("category", 1);
        // filelist.append("module", 'order');
        this.$api.qiNiuUploadFile(filelist).then((data) => {
          console.log('qiNiuUploadFile');
          console.log(data);
          // this.loading = false
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("Network exception, please try again");
          // this.loading = false
        });
        //保存文件
        // pdf.save(this.pdfName + '.pdf')
      })
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "Logout SuccessFully" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLibraryData(){
      this.loading = true;
      this.$api.getLibraryInfo(this.libraryId)
        .then((data) => {
          console.log('getLibraryData');
          console.log(data);
          let libraryDetail = data;
          this.printType = libraryDetail.category
          if(libraryDetail.category == 'spec'){
            this.getSpecData(JSON.parse(libraryDetail.jsonValue))
          }
          else if(libraryDetail.category == 'sds'){
            this.getSdsData(JSON.parse(libraryDetail.jsonValue))
          }
          else if(libraryDetail.category == 'composition'){
            this.getCompData(JSON.parse(libraryDetail.jsonValue))
          }
          else if(libraryDetail.category == 'coa'){
            this.getCoaData(JSON.parse(libraryDetail.jsonValue))
          }
          this.pdfName = libraryDetail.itemCode + libraryDetail.lotNumber
          this.getLibraryPdf(libraryDetail.itemCode,libraryDetail.lotNumber)
          if(this.uploadPdf == 1){
            setTimeout(() =>{
              this.getPdf()
            },1000);
          }
          else{
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getLibraryPdf(itemCode,lot){
      let params = {itemCode,lot}
      this.$api.libraryPdfUrl(params).then((data) => {
        console.log('------libraryPdfUrl-----')
        console.log(data)
        if(!data){
          setTimeout(() =>{
            this.getPdf()
          },1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getSpecData(data) {
      if (data.productType == "F&amp;M") {
        data.productType = "F&V";
      }
      console.info("getSpecData");
      console.info(data);
      if (data.confidential == "true") {
        data.confidential = true;
      } else {
        data.confidential = false;
      }
      if (data.jsonValue == undefined) {
        this.$message("Data does not exist");
        return false;
      }
      this.params = JSON.parse(data.jsonValue);
      console.info(this.params);
      this.params.productId = data.id;
      this.strSplit(this.params.ingredients, "ingredientsArr");
      this.strSplit(this.params.inciName, "inclNameArr");
      this.strSplit(this.params.botanicalName, "botanicalNameArr");
      this.strSplit(this.params.commonOtherName, "commonOtherNameArr");
      this.strSplit(this.params.partUsed, "partUsedArr");
      this.strSplit(this.params.percentage, "percentageArr");
      this.strSplit(this.params.processing, "processingArr");
      if (this.params.majorConstituentsInNativeHerbs != null) {
        this.params.majorConstituentsInNativeHerbs = this.params.majorConstituentsInNativeHerbs.replace(
          /_/g,
          ","
        );
      }
      if (data.productType == "SDNP-L") {
        if (this.params.odor == "" || this.params.odor == null) {
          this.params.odor = "Compares to standard";
        }
        if (this.params.taste == "" || this.params.taste == null) {
          this.params.taste = "Compares to standard";
        }
      }
      if (data.productType == "SHXTO") {
        if (!this.params.testMethodTotalColiforms) {
          this.params.testMethodTotalColiforms = "FDA-BAM";
        }
      }
      if (this.params.preservative != null) {
        this.params.preservative = this.params.preservative.replace(/_/g, ",");
        this.params.preservative = this.params.preservative.replace(/，/g, ",");
      }
      this.strSplit(this.params.commonName, "commonNameArr");
      if (data.productType == "CSFMU") {
        this.botanicalNameArr = this.ingredientsTags(0);
        this.commonOtherNameArr = this.ingredientsTags(1);
        this.partUsedArr = this.ingredientsTags(2);
      } else if (
        data.productType == "SDNP-L" ||
        data.productType == "Liquid EXT-S" ||
        data.productType == "Liquid EXT-B"
      ) {
        //如果是空则等于100
        if (this.params.analysisRangeYeastMold == "") {
          this.params.analysisRangeYeastMold = "100";
        }
        if (this.params.specificationEcoli == "Negative") {
          this.params.specificationEcoli = "";
        }
        if (this.params.specificationSalmonella == "Negative") {
          this.params.specificationSalmonella = "";
        }
        if (this.params.specificationStaphAureus == "Negative") {
          this.params.specificationStaphAureus = "";
        }
      }
      if (this.params.physicalProperties == "") {
        if (data.productType == "CSFMU-B" || data.productType == "FMU-D") {
          this.params.physicalProperties = "Blend";
        } else if (
          data.productType == "CSFMU-S" ||
          data.productType == "PCG-CSFMU-S" ||
          data.productType == "F&V" ||
          data.productType == "HEXT" ||
          data.productType == "REXT" ||
          data.productType == "OEXT" ||
          data.productType == "PCG" ||
          data.productType == "PCG-CSFMU-S"
        ) {
          this.params.physicalProperties = "Single";
        } else if (
          data.productType == "Liquid EXT-S" ||
          data.productType == "Liquid EXT-B"
        ) {
          this.params.physicalProperties = "Liquid";
        } else if (data.productType == "SHXTO") {
          this.params.physicalProperties = "Oil";
        } else if (data.productType == "EHXTO") {
          this.params.physicalProperties = "Essential oil";
        }
        if (this.params.appearance == "granular") {
          this.params.physicalProperties = "Granulate";
        }
      }
      //TODO:shTestArr push
      if (!this.params.shTest || this.params.shTest === null) {
        this.shTestArr.push({
          testLimitAcidValue: this.params.testLimitAcidValue,
          testLimitPeroxideValue: this.params.testLimitPeroxideValue,
          testLimitSaponificationValue: this.params
            .testLimitSaponificationValue,
          testLimitUnsaponifiableMaster: this.params
            .testLimitUnsaponifiableMaster,
          testLimitMoistureVolatiles: this.params.testLimitMoistureVolatiles,
          testLimitLead: this.params.testLimitLead,
          testLimitArsenic: this.params.testLimitArsenic,
          testLimitCadmium: this.params.testLimitCadmium,
          testLimitMercury: this.params.testLimitMercury,
          testLimitPesticides: this.params.testLimitPesticides,
          testMethodAcidValue: this.params.testMethodAcidValue,
          testMethodPeroxideValue: this.params.testMethodPeroxideValue,
          testMethodSaponificationValue: this.params
            .testMethodSaponificationValue,
          testMethodUnsaponifiableMaster: this.params
            .testMethodUnsaponifiableMaster,
          testMethodMoistureVolatiles: this.params.testMethodMoistureVolatiles,
          testMethodLead: this.params.testMethodLead,
          testMethodArsenic: this.params.testMethodArsenic,
          testMethodCadmium: this.params.testMethodCadmium,
          testMethodMercury: this.params.testMethodMercury,
          testMethodPesticides: this.params.testMethodPesticides,
          testLimitHeavyMetals: this.params.testLimitHeavyMetals
        });
      } else {
        this.shTestArr = JSON.parse(this.params.shTest);
      }

      if (
        !this.params.fattyAcidComposition ||
        this.params.fattyAcidComposition === null
      ) {
        this.fattyAcidCompositionArr.push({
          fattryLimitPalmitica: this.params.fattryLimitPalmitica,
          fattryLimitPalmitoleic: this.params.fattryLimitPalmitoleic,
          fattryLimitStearic: this.params.fattryLimitStearic,
          fattryLimitOleicVaccenic: this.params.fattryLimitOleicVaccenic,
          fattryLimitLinoleic: this.params.fattryLimitLinoleic,
          fattryLimitALinolenic: this.params.fattryLimitALinolenic,
          fatterLimitOthers: this.params.fatterLimitOthers,
          fattryMethodPalmitica: this.params.fattryMethodPalmitica,
          fattryMethodPalmitoleic: this.params.fattryMethodPalmitoleic,
          fattryMethodStearic: this.params.fattryMethodStearic,
          fattryMethodOleicVaccenic: this.params.fattryMethodOleicVaccenic,
          fattryMethodLinoleic: this.params.fattryMethodLinoleic,
          fattryMethodALinolenic: this.params.fattryMethodALinolenic,
          fatterMethodOthers: this.params.fatterMethodOthers
        });
      } else {
        this.fattyAcidCompositionArr = JSON.parse(
          this.params.fattyAcidComposition
        );
      }
      //非空默认值
      this.setEmptyDefaultValue();
      this.changeShelflife(this.params.productType, this.params.shelflife);
      this.params.specificationYeastAndMold = this.params.specificationYeastAndMold.replace(
        "<",
        ""
      );
      this.params.specificationYeastAndMold = this.params.specificationYeastAndMold.replace(
        "/g",
        ""
      );
      if (this.params.genSpecCustomField) {
        this.genSpecCustomFieldAttr = JSON.parse(
          this.params.genSpecCustomField
        );
      }
      if (this.params.analysisCustomField) {
        this.analysisCustomFieldAttr = JSON.parse(
          this.params.analysisCustomField
        );
      }
      if (this.params.micTestsCustomField) {
        this.micTestsCustomFieldAttr = JSON.parse(
          this.params.micTestsCustomField
        );
      }
      if (this.params.tableInputCustomField) {
        this.tableInputCustomFieldAttr = JSON.parse(
          this.params.tableInputCustomField
        );
      }
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach(v => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    //非空默认值
    setEmptyDefaultValue(){
        if(!this.params.pesticides){
        this.params.pesticides= "Conforms to USP<565>";
        }
        if(!this.params.testMethodArsenic){
        this.params.testMethodArsenic = "ICP-MS";
        }
        if(!this.params.testMethodCadmium){
        this.params.testMethodCadmium = "ICP-MS";
        }
        if(!this.params.testMethodLead){
        this.params.testMethodLead = "ICP-MS";
        }
        if(!this.params.testMethodMercury){
        this.params.testMethodMercury = "ICP-MS";
        }
    },
    changeShelflife(type,shelfile){
        //shelf life setting
        console.log(type)
        console.log(shelfile)
        console.info(type,shelfile)
        if(shelfile){
        this.params.shelflife = shelfile;
        return;
        }
        
        switch(type){
        case "CSFMU-S":
        case "CSFMU-B":
        case "SDNP":
        case "F&V":
        case "FMU-D":
        case "HEXT":
        case "PCG":
        case "REXT":
        case "OEXT":
        case "PCG-CSFMU-S":
        case "PCG-CSFMU-B":
            this.params.shelflife = "Three years unopened. Product is sensitive to heat and moisture.";
            this.params.shelflifeNumber = "36"
            break;
        case "Liquid EXT-B":
        case "Liquid EXT-S":
        case "SDNP-L":
            this.params.shelflife = "2 years unopened";
            this.params.shelflifeNumber = "24"
            break;
        case "SHXTO":
        case "EHXTO":
            this.params.shelflife = "Two years unopened in the original package.";
            this.params.shelflifeNumber = "24"
            break;
        case "TY-P":
            this.params.shelflife = "未开封情况下三年保质期。产品对热和潮湿敏感。";
            this.params.shelflifeNumber = "36"
            break;
        default:
            this.params.shelflife = "Three years unopened. Product is sensitive to heat and moisture.";
            this.params.shelflifeNumber = "36"
            break;
        }

        if(this.params.productType=='TY-P')
        {
        this.params.storage = "避光，密封，在干燥处保存。";
        }
        else if(this.params.productType!='Liquid EXT-S'&&this.params.productType!='Liquid EXT-B'&&this.params.productType!='SDNP-L'){ 
        if(!this.params.specificationEcoli){
            this.params.specificationEcoli="Negative";
            this.params.testMethodTotalColiforms = "FDA-BAM";
        }
        if(!this.params.specificationSalmonella){
            this.params.specificationSalmonella="Negative";
            this.params.testMethodTotalColiforms = "FDA-BAM";
        }
        if(!this.params.specificationStaphAureus){
            this.params.specificationStaphAureus="Negative";
            this.params.testMethodTotalColiforms = "FDA-BAM";
        }
        this.params.storage = "Preserve in airtight containers in a cool area, dry area.";
        }else{
        this.params.testMethodTotalColiforms = "FDA-BAM";
        this.params.storage = "Preserve in airtight containers in a cool area.";
        this.params.specificationEcoli="";
        this.params.specificationSalmonella="";
        this.params.specificationStaphAureus="";
        }
    },
    getSdsData(data) {
      this.formModel = data;
      if (this.formModel.inciName == null) {
        this.formModel.inciName = "";
      }
      console.log("this.formModel");
      console.log(this.formModel);
      let rowData = JSON.parse(data.rowData);
      this.formModel.rowData = rowData;

      this.formModel.rowData.forEach(element => {
        let array = element.substanceName;
        element.substanceName = [];
        array.forEach(subItem => {
          if (subItem.trim() != "") {
            element.substanceName.push(subItem);
          }
        });
      });
    },
    getCompData(data) {
      console.log("data");
      console.log(data);
      this.formData = data;
      this.createTime = this.formatTime(data.createTime, "yyyy-MM-dd");
      this.form = JSON.parse(data.jsonValue);
      if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
        this.form.BaseInfoManufacturingDate = "";
        this.form.BaseInfoExpirationDate = "";
      }
      this.loading = false;

      this.strSplit(this.form.CompINCIName, "inclNameArr");
      this.strSplit(this.form.CompCommonOtherName, "commonOtherNameArr");
      this.strSplit(this.form.CompBotanicalName, "botanicalNameArr");
      this.strSplit(this.form.CompPercentage, "percentageArr");
      this.CompCustomRowArr1 = JSON.parse(this.form.CompCustomRow1);
      this.CompCustomRowArr2 = JSON.parse(this.form.CompCustomRow2);
      this.CompCustomRowArr3 = JSON.parse(this.form.CompCustomRow3);
    },
    getTpsData(data) {
      if (data.jsonValue) {
        let rowData = JSON.parse(data.jsonValue);
        Object.keys(rowData).forEach(key => {
          this.params[key] = rowData[key];
        });
        console.log("======文件=======");
        console.log(this.$api.getFileUrl());
        console.log(rowData);
        console.log(rowData.FileNames);
        if (rowData.FileNames[0].names) {
          let i0 = 0;
          rowData.FileNames[0].names.forEach(element => {
            let nameK = rowData.FileNames[0].namesKey[i0];
            this.fileModel.SupplierSpecFileList.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i0++;
          });
        }
        if (rowData.FileNames[1].names) {
          let i1 = 0;
          rowData.FileNames[1].names.forEach(element => {
            let nameK = rowData.FileNames[1].namesKey[i1];
            this.fileModel.SupplierCoaFileList.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i1++;
          });
        }
        if (rowData.FileNames[2].names) {
          let i2 = 0;
          rowData.FileNames[2].names.forEach(element => {
            let nameK = rowData.FileNames[2].namesKey[i2];
            this.fileModel.SupplierSdsFileList.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i2++;
          });
        }
        if (rowData.FileNames[3].names) {
          let i3 = 0;
          rowData.FileNames[3].names.forEach(element => {
            let nameK = rowData.FileNames[3].namesKey[i3];
            this.fileModel.OthersAttachmentsFileList.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i3++;
          });
        }
        if (rowData.FileNames[4].names) {
          let i4 = 0;
          rowData.FileNames[4].names.forEach(element => {
            let nameK = rowData.FileNames[4].namesKey[i4];
            this.fileModel.CustomRowFileList1.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i4++;
          });
        }
        if (rowData.FileNames[5].names) {
          let i5 = 0;
          rowData.FileNames[5].names.forEach(element => {
            let nameK = rowData.FileNames[5].namesKey[i5];
            this.fileModel.CustomRowFileList2.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i5++;
          });
        }
        if (rowData.FileNames[6].names) {
          let i6 = 0;
          rowData.FileNames[6].names.forEach(element => {
            let nameK = rowData.FileNames[6].namesKey[i6];
            this.fileModel.CustomRowFileList3.push({
              name: element,
              url: this.$api.getFileUrl() + "/" + nameK,
              nameKey: nameK
            });
            i6++;
          });
        }
      }
    },
    getCoaData(data) {
      console.log("getCoaData:");
      console.log(data);
      this.formData = data;
      this.form = JSON.parse(data.jsonValue);
      if(this.form.baseInfoCustomField){
        this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
      }
      console.log("getCoaData====form:");
      console.log(this.form);
      if(this.form.pdfUrl){
        let pdfJson =  JSON.parse(this.form.pdfUrl).pdfUrl
        console.log('pdfJson')
        console.log(pdfJson)
        this.pdfUrl = pdfJson
      }

      if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
        this.form.BaseInfoManufacturingDate = "";
        this.form.BaseInfoExpirationDate = "";
      }
      if (this.form.productType == "OilEhxto") {
        this.form.productType = "EHXTO";
      } else if (this.productType == "OilShxto") {
        this.form.productType = "SHXTO";
      }
      if(this.form.productType =='power'){
        this.form.productType ='Powder'
      }
      console.log('this.form.productType')
      console.log(this.form.productType)
      this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
      this.strSplit(
        this.form.GeneralSpecCommonNameOrOtherName,
        "commonNameArr"
      );
      this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
      this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");

      this.strSplit(this.form.GeneralSpecIngredients, "ingredientsArr");
      this.strSplit(this.form.GeneralSpecPercentage, "percentageArr");

      if (this.form.genSpecCustomField) {
        this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
      }
      if (this.form.analysisCustomField) {
        this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
      }
      if (this.form.micTestsCustomField) {
        this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
      }
      if (this.form.tableInputCustomField) {
        this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
      }
    },
    printSpecPage(){
        if(this.params.majorConstituentsInNativeHerbs){
        this.strSplit(this.params.majorConstituentsInNativeHerbs.replace(/,/g,"_"), "majorConstituentsInNativeHerbsArr");
        }
        this.printShow = true;
        const style = '@page {size:  auto; margin:3mm} @media print{} '
        var printFileName = this.params.productName+"Rev."+this.noteTableData.length;
        document.title = "(spec)"+printFileName;
        setTimeout(() =>{
            printJS({
            printable:'printSpecDiv',
            type:'html',
            style:style,
            scanStyles:false
            })
        },1.5);
        setTimeout(() =>{
            this.printShow = false;   
        },3);
    },
    printSdsPage() {
      const style = "@page {margin:0} @media print{}";
      var printFileName = this.formData.productName;
      document.title = "(SDS)" + printFileName;
      setTimeout(() => {
        printJS({
          printable: "printSdsDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
    },
    printCompPage() {
      const style = "@page {margin:0} @media print{}";
      var printFileName = ""; 
      if(this.params.BaseInfoProductName!=""&&this.params.BaseInfoProductName!=undefined){
        printFileName ="(Composition) "+ this.params.BaseInfoProductName.replace(":","~").replace("：", "~");
      }
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printCompDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
    },
    printCoaPage() {
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = "(CoA)"+this.form.BaseInfoProductName+this.form.BaseInfoLot
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printCoaDiv",
          type: "html",
          style: style,
          scanStyles: false,
          documentTitle: printFileName,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
  },
  mounted() {
    console.log("library----start")
    let libraryId = this.$route.query.id;
    if(libraryId){
      this.libraryId = libraryId
      this.getLibraryData()
    }
    let source = this.$route.query.source;
    if(source){
      this.hideHead = true
      this.source = source
    }
    if(this.$route.query.uploadPdf){
      this.uploadPdf = this.$route.query.uploadPdf
    }
    // if(this.$route.query.filename){
    //   let Base64 = require('js-base64').Base64
    //   this.pdfName = Base64.decode(this.$route.query.filename);//解密
    //   console.log('this.pdfName==='+this.pdfName)
    // }
    
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
